.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.welcome-animation {
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.welcome-animation.show {
  opacity: 1;
  transform: translateY(0);
}

/* Popup */
.alert {
  position: fixed;
  top: -150px;
  left: 87%;
  transform: translateX(-50%);
  width: 300px;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s ease-out;
}

.alert.show {
  top: 20px;
  opacity: 1;
}

.alert-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffc107;
  color: #ffffff;
}

.alert-content > p {
  margin: 0;
}

.alert-dismiss {
  cursor: pointer;
}

.alert-dismiss:hover {
  text-decoration: underline;
}


/* Responsive ness */
@media only screen and (max-width:767px){
  .alert {
    position: fixed;
    top: 0;
    left: 60%;
    transform: translateX(-50%);
    width: 300px;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s ease-out;
  }
}


.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.toggle-popup-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
}

body.light {
  background-color: rgb(239, 239, 239);
  color:black;
}


body.dark {
  background-color: #28282B;
  color: white;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.overlay-text::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); 
  z-index: -1;
}

#homedashboard{
  margin: 30px;
}

#addpro{
  margin-left: 4%;
}

#tables{
  margin: 50px;
}

@media screen and (max-width: 1080px) {
  #navbarclass{
    margin-left: 10%;
  }  

  #homedashboard{
    margin-left: 15%;
  }

  #addpro{
    margin-left: 15%;
  }

  #tables{
    margin-left: 100px;
  }
}

